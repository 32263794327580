<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Cadastrar Pack</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-12 card">
          <form>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12">
                  <label>Nome</label>
                  <input type="text" class="form-control" maxlength="100" v-model="pack.name"
                         style="text-transform: uppercase"></input>
                </div>
              </div>
              <hr/>
              <div class="row form-group">
                <div class="col-sm-6">
                  <label>Descrição</label>
                  <textarea class="form-control" rows="5" maxlength="255" v-model="description.description"></textarea>
                </div>
                <div class="col-sm-6">
                  <label>Detalhes Internos</label>
                  <textarea class="form-control" rows="5" maxlength="1000"
                            v-model="description.internalDetails"></textarea>
                </div>
              </div>
            </div>
            <div class="card-footer text-right">
              <base-button type="success" @click.prevent="savePack">Salvar</base-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {callWs, failWs} from '@/ws.service'

  export default {
    components: {},
    data() {
      return {
        pack: {
          name: '',
        },
        description: {
          description: '',
          internalDetails: ''
        }
      }
    },
    mounted() {
      this.callAllPacks()
    },
    methods: {
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      validate() {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.registerForm, isValid)
        })
      },
      savePack() {
        if (this.pack.name == '') {
          this.$swal({
            title: 'Aviso!',
            text: 'O campo Nome é obrigatório!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            timer: 5000,
            icon: 'warning'
          })
          return
        }
        callWs("/role/insert-role-pack", true,
          "POST", null, true, null,
          {
            name: this.pack.name.toUpperCase(), idDescription: null,
            newDescription: {
              description: this.description.description,
              internalDetails: this.description.internalDetails
            }
          },
          () => {
            this.$swal({
              title: 'Sucesso!',
              text: 'Dados salvos com sucesso.',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-success btn-fill'
              },
              timer: 5000,
              icon: 'success'
            })
          }, (error) => {
            this.buttonDisabled = false
            if (error.response.status == 409) {
              this.$swal({
                title: 'Aviso!',
                text: 'Pack já cadastrada!',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-warning btn-fill'
                },
                timer: 5000,
                icon: 'warning'
              })
              return
            }
            this.$swal({
              title: 'Falha!',
              text: 'Falha ao salvar a role, verifique os dados e tente novamente!',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-danger btn-fill'
              },
              timer: 5000,
              icon: 'error'
            })
          })
      }
    }
  }
</script>
<style>
  .uppercase {
    text-transform: uppercase;
  }
</style>
